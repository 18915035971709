import React, { ReactElement } from 'react'
import pluralize from 'pluralize'

import { useEmailResend } from './hooks'

type EmailSentProps = {
  email: string
}

export default function EmailSent({ email }: EmailSentProps): ReactElement {
  const {
    countdown,
    isResending,
    isResendSuccessful,
    error,
    handleButtonClick,
  } = useEmailResend(email)

  return (
    <>
      <h1>Email Sent!</h1>
      <p>
        Email with password recovery link has been sent&nbsp;to{' '}
        <span className="password-reset__text--bold">{email}</span>.
      </p>
      <p>Click on the link in the email to create a&nbsp;new&nbsp;password.</p>
      <p>
        If you haven&apos;t received the email, request it again{' '}
        {countdown > 0 ? `in ${pluralize('second', countdown, true)}.` : 'now:'}
      </p>
      <button
        className="btn btn-secondary"
        disabled={countdown > 0 || isResending}
        onClick={handleButtonClick}
      >
        Get email again
      </button>
      {!isResending && isResendSuccessful && (
        <p className="password-reset__alert password-reset__alert--success">
          The email has been sent again
        </p>
      )}
      {!isResending && error && (
        <p className="password-reset__alert password-reset__alert--error">
          Something went wrong. Please try again
        </p>
      )}
    </>
  )
}
