import React, { ReactNode } from 'react'
import { Link, navigate } from 'gatsby'
import classNames from 'classnames'

import './LinkBack.scss'

type LinkBackProps = {
  children: ReactNode
  webview?: boolean
  to?: string
}

export default function LinkBack({ children, webview, to }: LinkBackProps) {
  return !!to ? (
    <Link
      className={classNames('link-back', {
        'link-back--webview btn btn-secondary': webview,
      })}
      to={webview ? `/webview${to}` : to}
    >
      {children}
    </Link>
  ) : (
    <a className="link-back" onClick={() => navigate(-1)}>
      {children}
    </a>
  )
}
