import React from 'react'
import { Link } from 'gatsby'
import { RouteComponentProps } from '@gatsbyjs/reach-router'

import FormInput from 'components/FormInput'
import { useNewPassword } from './hooks'

import './PasswordReset.scss'

interface NewPasswordProps extends RouteComponentProps {
  code?: string
}

export default function NewPassword(props: NewPasswordProps) {
  const {
    formRegister,
    formIsDirty,
    formError,
    isSubmitting,
    isSubmitSuccessful,
    handleSubmit,
  } = useNewPassword(props.code)

  return (
    <div className="row justify-content-center">
      <div className="col-md-6 col-xl-4">
        <h1>Create Password</h1>
        <p className="password-reset__description__paragraph">
          Please make sure your password is at least 8&nbsp;characters long
          and&nbsp;contains:
        </p>
        <ul className="password-reset__description__list">
          <li>at least one latin character</li>
          <li>at least one digit</li>
          <li>at least one non-alphanumeric character</li>
        </ul>
        <form onSubmit={handleSubmit}>
          <FormInput
            type="password"
            name="password"
            placeholder="New Password"
            autoComplete="password"
            ref={formRegister}
            isDirty={formIsDirty}
            error={formError}
          />
          <button
            className="btn btn-primary"
            disabled={isSubmitting || !formIsDirty}
          >
            Set Password
          </button>
          {!isSubmitting && isSubmitSuccessful && (
            <p className="password-reset__alert password-reset__alert--success">
              New password created! Use&nbsp;it to{' '}
              <Link to="/dashboard">sign&nbsp;in</Link>.
            </p>
          )}
        </form>
      </div>
    </div>
  )
}
