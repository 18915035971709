import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { useFetch } from '../../../hooks'
import { passwordResetSend } from '../../../services/api'

type EmailFormValue = {
  email: string
}

type PasswordResetSendParams = {
  email: string
}

const PASSWORD_RESET_SCHEMA = yup.object().shape({
  email: yup
    .string()
    .required('Email is a required field')
    .email('Email must be a valid email'),
})

export function useEmailEnterForm() {
  return useForm<EmailFormValue>({
    resolver: yupResolver(PASSWORD_RESET_SCHEMA),
    mode: 'onChange',
    defaultValues: { email: '' },
  })
}

export function useEmailEnterSubmit() {
  const { fetch, isFetching, isFetchSuccessful, data, error, reset } = useFetch(
    async (params: PasswordResetSendParams) => {
      return await passwordResetSend(params)
    },
  )

  return {
    submit: fetch,
    isSubmitting: isFetching,
    isSubmitSuccessful: isFetchSuccessful,
    error,
    data,
    reset,
  }
}

export default function useEmailEnter(setResult: (result: string) => void) {
  const form = useEmailEnterForm()
  const submit = useEmailEnterSubmit()

  useEffect(() => {
    if (form.formState.isValid) {
      submit.reset()
    }
  }, [form.formState.isValid])

  useEffect(() => {
    if (!submit.isSubmitting && submit.error) {
      form.reset({ email: '' })
      form.setError('email', {
        message: submit.error.includes('403')
          ? 'Account with this email does not exist'
          : 'Something went wrong. Please try again',
      })
    }
    if (!submit.isSubmitting && submit.isSubmitSuccessful) {
      const { email } = form.getValues()
      setResult(email)
    }
  }, [submit.isSubmitting, submit.isSubmitSuccessful, submit.error])

  return {
    form,
    submit,
    handleSubmit: form.handleSubmit(submit.submit),
  }
}
