import React, { ReactElement } from 'react'
import { usePasswordReset } from './hooks'
import { RouteComponentProps } from 'types/reach-router'

import LinkBack from 'components/LinkBack'
import EmailEnter from './EmailEnter'
import EmailSent from './EmailSent'

import './PasswordReset.scss'

export default function PasswordReset(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  props: RouteComponentProps,
): ReactElement {
  const { email, setEmail, resetInitiated } = usePasswordReset()

  return (
    <div className="row">
      <div className="col-md-3 col-xl-4">
        <LinkBack>&#8592; Go back</LinkBack>
      </div>
      <div className="col-md-6 col-xl-4">
        {!resetInitiated ? (
          <EmailEnter setResult={setEmail} />
        ) : (
          <EmailSent email={email} />
        )}
      </div>
    </div>
  )
}
