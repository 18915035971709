import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useFetch } from '../../../hooks'
import { passwordResetNewPassword } from '../../../services/api'

type PasswordFormValue = {
  password: string
}

const NEW_PASSWORD_SCHEMA = yup.object().shape({
  password: yup
    .string()
    .required('Password cannot be empty')
    .min(8, 'Password should be at least 8 characters long')
    .matches(/[a-zA-Z]+/, 'Password should contain at least one letter')
    .matches(/\d+/, 'Password should contain at least one number')
    .matches(/\W+/, 'Password should contain at least one special symbol'),
})

export function useNewPasswordForm() {
  return useForm<PasswordFormValue>({
    resolver: yupResolver(NEW_PASSWORD_SCHEMA),
    mode: 'onSubmit',
    defaultValues: { password: '' },
  })
}

export function useNewPasswordSubmit(code: string) {
  const { fetch, isFetching, isFetchSuccessful, data, error, reset } = useFetch(
    async (value: PasswordFormValue) => {
      return await passwordResetNewPassword({ ...value, code })
    },
  )

  return {
    submit: fetch,
    isSubmitting: isFetching,
    isSubmitSuccessful: isFetchSuccessful,
    error,
    data,
    reset,
  }
}

export default function useNewPassword(code: string) {
  const {
    formState,
    register,
    reset: formReset,
    setError: formSetError,
    handleSubmit,
  } = useNewPasswordForm()
  const {
    submit,
    isSubmitting,
    isSubmitSuccessful,
    reset: submitReset,
    error: submitError,
  } = useNewPasswordSubmit(code)

  useEffect(() => {
    if (formState.dirtyFields.password && !formState.errors.password) {
      submitReset()
    }
  }, [formState.dirtyFields, formState.errors])

  useEffect(() => {
    if (!isSubmitting && submitError) {
      formReset({ password: '' })
      formSetError('password', {
        message: submitError.includes('403')
          ? 'Your password reset link has expired. Try resetting password again.'
          : 'Something went wrong. Please try again.',
      })
    }
  }, [isSubmitting, submitError])

  return {
    formRegister: register,
    formIsDirty: formState.dirtyFields.password,
    formError: formState.errors.password,
    submit,
    isSubmitting,
    isSubmitSuccessful,
    handleSubmit: handleSubmit(submit),
  }
}
