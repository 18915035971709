import React from 'react'

import FormInput from 'components/FormInput'
import { useEmailEnter } from './hooks'

export default function EmailEnter({
  setResult,
}: {
  setResult: (result: string) => void
}) {
  const { form, submit, handleSubmit } = useEmailEnter(setResult)

  return (
    <>
      <h1>Forgot Password?</h1>
      <p>
        We&apos;ll email you instructions on how to create
        a&nbsp;new&nbsp;password.
      </p>
      <form className="password-reset-form" onSubmit={handleSubmit}>
        <FormInput
          type="email"
          name="email"
          placeholder="Your Email"
          autoComplete="email"
          ref={form.register}
          isDirty={form.formState.dirtyFields.email}
          error={form.errors.email}
        />
        <button
          className="password-reset-form__submit btn btn-primary"
          type="submit"
          disabled={submit.isSubmitting || !form.formState.isValid}
        >
          Get Instructions
        </button>
      </form>
    </>
  )
}
