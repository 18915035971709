import { useEffect } from 'react'
import { useFetch, useCountdownTimer } from '../../../hooks'
import { passwordResetSend } from '../../../services/api'
import { RESEND_DELAY } from '../../../settings'

export default function useEmailResend(email: string) {
  const [countdown, resetCountdown] = useCountdownTimer(RESEND_DELAY)
  const {
    fetch: resend,
    isFetching: isResending,
    isFetchSuccessful: isResendSuccessful,
    error,
    reset,
  } = useFetch(async () => {
    return await passwordResetSend({ email })
  })

  useEffect(() => {
    if (!isResending && countdown === 0) {
      reset()
    }
  }, [isResending, countdown])

  function handleButtonClick(): void {
    resetCountdown()
    resend()
  }

  return {
    countdown,
    isResending,
    isResendSuccessful,
    error,
    handleButtonClick,
  }
}
