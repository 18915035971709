import React, { ReactElement } from 'react'
import { Router } from '@gatsbyjs/reach-router'

import AuthProvider from 'contexts/AuthProvider'
import UserProvider from 'contexts/UserProvider'
import Layout from 'components/Layout'
import PasswordReset, { NewPassword } from 'components/PasswordReset'

export default function PasswordResetPage(): ReactElement {
  return (
    <AuthProvider>
      <UserProvider>
        <Layout className="password-reset-page">
          <Router basepath="/reset-password">
            <PasswordReset path="/" />
            <NewPassword path="/:code" />
          </Router>
        </Layout>
      </UserProvider>
    </AuthProvider>
  )
}
